import { useToast } from "@chakra-ui/react"
import { Box } from "@dladio/core-ui"
import { erpMethodApi, erpResourceApi, googleMapApi, SERVICE } from "@dladio/service"
import { isArray } from "@dladio/utils"
import { DefaultButton } from "@fluentui/react"
import _ from "lodash"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPosition, setValue } from "../../../../reducers/mapMenuReducer"
import { addGeo } from "../../../../reducers/mapReducer"
import GoogleMap from "../GoogleMap/GoogleMap"
import Marker from "../Marker/Marker"
import Polylines from "../Polylines/Polylines"


function decode(encodedPath: string) {
    const len = encodedPath.length;
    const path = [];
    let index = 0;
    let lat = 0;
    let lng = 0;
    while (index < len) {
        let result = 1;
        let shift = 0;
        let b;
        do {
            b = encodedPath.charCodeAt(index++) - 63 - 1;
            result += b << shift;
            shift += 5;
        } while (b >= 0x1f);
        lat += (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
        result = 1;
        shift = 0;
        do {
            b = encodedPath.charCodeAt(index++) - 63 - 1;
            result += b << shift;
            shift += 5;
        } while (b >= 0x1f);
        lng += (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
        path.push({ lat: lat * 1e-5, lng: lng * 1e-5 });
    }
    return path;
}

const RouteMap = () => {

    const dispatch = useDispatch()
    const { geoList, waypoints } = useSelector((state: any) => state.map)
    const [dd, setDD] = useState(null)

    useEffect(() => {
        init()

    }, [])

    useEffect(() => {
        getWaypoints()
    }, [waypoints])

    const init = async () => {
        const { data } = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: "fa2db41d64" }
        }))

        getGeoLocations(data?.message)
    }


    const getWaypoints = () => {
        setTimeout(() => {

            if (google && isArray(waypoints) && waypoints?.length > 0) {
                const directionsService = new google.maps.DirectionsService();
                const directionsRenderer = new google.maps.DirectionsRenderer();

                const origin = waypoints[0]
                const destination = waypoints[waypoints.length - 1]
                let waypointsList = _.cloneDeep(waypoints)

                if (waypoints.length > 2) {
                    waypointsList.shift()
                    waypointsList.pop()
                } else {
                    waypointsList = []
                }

                directionsService.route(
                    {
                        origin: origin,
                        destination: destination,
                        waypoints: waypointsList.map((line: any) => ({
                            location: line
                        })),
                        travelMode: google.maps.TravelMode.DRIVING
                    },
                    (result: any, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            setDD(result?.routes[0]?.overview_polyline)
                        } else {
                            console.error(`error fetching directions ${result}`);
                        }
                    }
                );
            } else {

            }
        }, 500);
    }

    const getGeoLocations = useCallback(async (list: any) => {
        if (isArray(list)) {

            for (const { AddressObj, CardCode, CardName } of list) {

                const { data } = await dispatch(googleMapApi.endpoints.geocode.initiate({
                    address: `${_.get(AddressObj, 'Street')}, ${_.get(AddressObj, 'City')}, ${_.get(AddressObj, 'State')}, ${_.get(AddressObj, 'ZipCode')}`
                }))

                let Location = _.get(data, ['results', 0, 'geometry', 'location'])
                dispatch(addGeo({ CardCode, CardName, Location }))
            }

        }
    }, [])

    let markerMarkup = isArray(geoList) && geoList.map(({ Location, CardName, CardCode }: any, i: number) => (
        <Marker onClick={(value: any, e: any) => {
            dispatch(setValue(value))
            dispatch(setPosition({ top: e?.domEvent?.clientY, left: e?.domEvent?.clientX }))
        }
        } label={CardName} clickable={true} key={i} position={Location} value={{ Location, CardName, CardCode }} />
    ))

    console.log(dd);

    return (
        <div>
            <GoogleMap
                onClick={() => dispatch(setPosition({ top: 0, left: 0 }))}
                onChangeZoom={() => dispatch(setPosition({ top: 0, left: 0 }))}
            >
                {markerMarkup}
                <Polylines
                    path={decode(dd ?? '')}
                    geodesic={true}
                    strokeColor={"#FF0000"}
                    strokeOpacity={1.0}
                    strokeWeight={2} />
            </GoogleMap>
            <MapMenu />
        </div>
    )
}

const MapMenu = () => {
    const dispatch = useDispatch()
    const toast = useToast()
    const { position, value } = useSelector((state: any) => state.mapMenu)
    const { date } = useSelector((state: any) => state.map)

    const addToTRoute = async () => {
        let toastRef = toast({
            title: 'Processing.',
            description: "Stay Calm.",
            status: 'info',
            duration: 9000,
            isClosable: true,
        })

        dispatch(setPosition({ top: 0, left: 0 }))

        const { error: countError, data: count } = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: SERVICE.EXEC_REPORT_QUERY,
            body: {
                name: "route_count",
                date: moment(new Date()).format("YYYY/MM/DD")
            }
        }, { forceRefetch: true }))


        if (!countError) {
            const { error, data }: any = await dispatch(
                erpResourceApi.endpoints.create.initiate({
                    doc: "DLAD Route Plane",
                    payload: {
                        card_code: _.get(value, 'CardCode'),
                        card_name: _.get(value, 'CardName'),
                        location: JSON.stringify(_.get(value, 'Location')),
                        sequence: _.get(count, 'message[0].value') + 1,
                        doc_date: moment(date).format("YYYY/MM/DD")
                    },
                })
            );

            if (!error) {
                toast.update(toastRef as any, {
                    title: 'Add to route.',
                    description: "Party added to your route.",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

    }

    const markup = position?.top > 0 && (
        <Box className="absolute w-[180px] bg-transparent" style={{ top: position?.top + 'px' ?? 0, left: position?.left + 'px' ?? 0 }} title={value?.CardName}>
            <DefaultButton onClick={addToTRoute}>Add to route</DefaultButton>
        </Box>
    )

    return <>{markup}</>
}

export default RouteMap