import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    position: { top: 0, left: 0 },
    value: {}
}

const mapMenuSlice = createSlice({
    name: 'mapMenu',
    initialState,
    reducers: {
        setPosition(state: any, { payload }) {
            state.position = payload
        },
        setValue(state: any, { payload }) {
            state.value = payload
        }
    },
})

export const { setPosition, setValue } = mapMenuSlice.actions

export default mapMenuSlice