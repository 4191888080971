
import { Box, CoreUIProvider, DefaultCommandBar, DefaultTable, Stack, StatCard, StatCardList, TitleBar } from "@dladio/core-ui"
import Home from "./pages/home/home";

function App() {
  return (
    <CoreUIProvider>
        <Home/>
    </CoreUIProvider >
  );
}

export default App;
