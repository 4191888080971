import { erpMethodApi, erpResourceApi, googleMapApi } from '@dladio/service';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit'
import mapMenuSlice from '../reducers/mapMenuReducer';
import mapSlice from '../reducers/mapReducer';

export const store = configureStore({
  reducer: combineReducers({
    [mapMenuSlice.name]: mapMenuSlice.reducer,
    [mapSlice.name]: mapSlice.reducer,
    [erpResourceApi.reducerPath]: erpResourceApi.reducer,
    [erpMethodApi.reducerPath]: erpMethodApi.reducer,
    [googleMapApi.reducerPath]: googleMapApi.reducer
  }),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(googleMapApi.middleware)
      .concat(erpResourceApi.middleware)
      .concat(erpMethodApi.middleware)
      .concat(googleMapApi.middleware)
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
