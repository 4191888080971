import { useEffect, useState } from "react";

const Polylines = (options: any) => {
    const [poly, setPoly] = useState<any>();

    useEffect(() => {
        if (!poly) {
            setPoly(new google.maps.Polyline());
        }

    }, [poly]);

    useEffect(() => {
        if (poly) {
            poly.setOptions(options);
        }
    }, [poly, options]);

    return null;
};

export default Polylines