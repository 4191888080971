import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    geoList: [],
    count: 0,
    date: new Date(),
    waypoints: []
}

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        addGeo(state: any, { payload }) {
            state.geoList = [...state.geoList, payload]
        },
        setDate(state: any, { payload }) {
            state.date = payload
        },
        setWaypoint(state: any, { payload }) {
            state.waypoints = payload
        }
    },
})

export const { addGeo, setDate, setWaypoint } = mapSlice.actions

export default mapSlice