import { Box, DefaultCommandBar, Stack, TitleBar } from "@dladio/core-ui";
import { Checkbox, DatePicker, Text } from "@fluentui/react";
import RoutList from "./components/RouteList/RouteList";
import RouteMap from "./components/RouteMap/RouteMap";



const Home = () => {
    return (
        <>
            <TitleBar title="Route Planner" subTitle="v0.0.1" />
            <DefaultCommandBar actions={[{
                key: 1,
                text: 'Back',
                iconProps: { iconName: 'Back' },
                onClick: () => { alert() },
            }]} />
            <Stack>
                <RoutList />
                <Box title="Map">
                    <RouteMap />
                </Box>
            </Stack>
        </>
    )
}

export default Home