import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";
import React from "react";

const GoogleMap = ({ children, onClick, onChangeZoom }: any) => {

    const [zoom, setZoom] = React.useState(11); // initial zoom
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: -33.8823618,
        lng: 151.1145099,
    });

    const onIdle = (m: google.maps.Map) => {
        setZoom(m.getZoom()!);
        setCenter(m.getCenter()!.toJSON());
    };

    const render = (status: Status) => {
        return <h1>{status}</h1>;
    };


    return (
        <Wrapper apiKey={"AIzaSyDvgMpLFrIULFqDQHujudX19wXXZO06tMc"} render={render}>
            <Map
                onClick={() => onClick && onClick()}
                onChangeZoom={() => onChangeZoom && onChangeZoom()}
                center={center}
                onIdle={onIdle}
                zoom={zoom}
                style={{ flexGrow: "1" }}
            >
                {children}
            </Map>
        </Wrapper>
    )
}


interface MapProps extends google.maps.MapOptions {
    children: any
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
}

const Map = ({
    onClick,
    onChangeZoom,
    onIdle,
    children,
    style,
    ...options
}: any) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<any>();
    const directionsRenderer = new google.maps.DirectionsRenderer();


    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }

        setTimeout(() => {
            ref.current && (ref.current.style.height = `calc(100vh - 20px - ${ref.current.offsetTop}px)`)
        }, 400)
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
            directionsRenderer.setMap(map)
        }
    }, [map, options]);

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onChangeZoom) {
                map.addListener("zoom_changed", onChangeZoom)
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    //@ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a: any, b: any) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
        ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        // TODO extend to other types

        // use fast-equals for other objects
        return deepEqual(a, b);
    }
);

function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}


export default GoogleMap