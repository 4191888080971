import { useToast } from "@chakra-ui/react"
import { Box, Stack } from "@dladio/core-ui"
import { erpMethodApi, erpResourceApi, useListQuery } from "@dladio/service"
import { isArray, isJSON } from "@dladio/utils"
import { Checkbox, DatePicker, DefaultButton, FontIcon, IDropdownOption, Text } from "@fluentui/react"
import _ from "lodash"
import moment from "moment"
import { useEffect, useRef } from "react"
import DraggableList from "react-draggable-list"
import { useDispatch, useSelector } from "react-redux"
import { setDate, setWaypoint } from "../../../../reducers/mapReducer"

const RoutList = () => {
    const dispatch = useDispatch()

    const { date, waypoints } = useSelector((state: any) => state.map)
    const { data, refetch } = useListQuery({
        method: "DLAD Route Plane",
        fields: ['*'],
        filters: JSON.stringify([["doc_date", "=", moment(date).format("YYYY/MM/DD")]])
    })
    const ref: any = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            ref.current && (ref.current.style.height = `calc(100vh - 20px - ${ref.current.offsetTop}px)`)
        }, 400)
    }, [ref])

    useEffect(() => {
        refetch()
    }, [date])

    useEffect(() => {
        isArray(_.get(data, 'data')) && dispatch(setWaypoint(_.get(_.cloneDeep(data), 'data').sort((a: any, b: any) => { return a?.sequence - b?.sequence }).map((line: any) => isJSON(line?.location) && JSON.parse(line?.location))))
    }, [data])

    return (
        <Box className=" w-1/3" title="Routes">
            <DatePicker
                value={date}
                ariaLabel="Select a date"
                onSelectDate={(date) => dispatch(setDate(date))}
            />
            <div ref={ref} className="divide-y py-2 overflow-auto">
                {isArray(_.get(data, 'data')) && _.get(_.cloneDeep(data), 'data').sort((a: any, b: any) => { return a?.sequence - b?.sequence }).map((line: any) => (<ListItem title={line?.card_name} data={line} />))}
            </div>
        </Box>
    )
}

const ListItem = ({ title, data }: any) => {
    const dispatch = useDispatch()
    const toast = useToast()
    const toastRef = useRef()

    const setChecked = async (value: any) => {
        let toastRef = toast({
            title: 'Processing.',
            description: "Stay calm.",
            status: 'info',
            duration: 4000,
            isClosable: true,
        })

        const { error, data: res }: any = await dispatch(
            erpResourceApi.endpoints.update.initiate({
                doc: "DLAD Route Plane",
                name: data?.name,
                payload: {
                    status: value ? '1' : '0'
                },
            })
        );

        if (!error) {
            toast.update(toastRef as any, {
                title: value ? 'Mark as complete.' : 'Remove mark.',
                description: value ? "The route is finished." : 'Remove your mark.',
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast.update(toastRef as any, {
                title: 'Cannot update.',
                description: "Try to authenticate.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
        }
    }

    const remove = async (value: any) => {
        let toastRef = toast({
            title: 'Processing.',
            description: "Stay calm.",
            status: 'info',
            duration: 4000,
            isClosable: true,
        })
        const { error, data: res }: any = await dispatch(
            erpResourceApi.endpoints.delete.initiate({
                doc: "DLAD Route Plane",
                name: data?.name
            })
        )


        if (!error) {
            toast.update(toastRef as any, {
                title: 'Removed.',
                description: "The route is removed.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        } else {
            toast.update(toastRef as any, {
                title: 'Cannot update.',
                description: "Try to authenticate.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
        }
    }

    return (
        <div className=" bg-transparent px-1 py-2">
            <Stack className="bg-transparent justify-between align-middle">
                <Text>{title}</Text>
                <Stack className="bg-transparent gap-2">
                    <FontIcon className="cursor-pointer opacity-0 hover:opacity-100" iconName="Delete" onClick={remove} />
                    <Checkbox checked={(_.get(data, 'status') === "1")} onChange={(e, value) => setChecked(value)} />
                </Stack>
            </Stack>
        </div>
    )
}

export default RoutList